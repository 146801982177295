<template>
  <div>
    <div class="tab-pane">
      <form @submit.prevent="sendPassword">
        <div class="general-settings">
          <div class="general-settings__item">
            <p class="general-settings__item--name">
              {{ $t('settings.email') }}
            </p>
            <p class="general-settings__item--value">
              {{ email }}
            </p>
          </div>
          <div class="general-settings__item edit">
            <p class="general-settings__item--name">
              {{ $t('settings.password') }}
            </p>
            <div class="general-settings__item--value">******</div>
            <div
              class="general-settings__item--edit"
              :class="{ 'active-edit': editActive }"
              @click="editActive = !editActive"
            >
              <span class="rbi rbi-edit" />
              <span>{{ $t('settings.edit') }}</span>
            </div>
          </div>
          <div v-show="editActive">
            <div class="general-settings__item line-off">
              <div class="form-group">
                <input
                  id="fpas"
                  v-model="newPassword"
                  name="fPassword"
                  class="form-control"
                  type="password"
                  :placeholder="$t('settings.newPassword')"
                />
                <div v-show="err.first('fPassword')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('fPassword') }}</span>
                </div>
              </div>
            </div>
            <div class="general-settings__item line-off">
              <div class="form-group">
                <input
                  id="lpas"
                  v-model="repeatPassword"
                  name="lPassword"
                  class="form-control"
                  type="password"
                  :placeholder="$t('settings.repeatPswd')"
                />
                <div v-show="err.first('lPassword')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('lPassword') }}</span>
                </div>
              </div>
            </div>
            <div class="control-box btn-wrap">
              <rb-button class="btn-black">
                {{ $t('settings.submit') }}
              </rb-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Validator } from 'vee-validate';
import RbButton from '../../Button';

export default {
  validator: null,
  components: {
    RbButton,
  },
  data() {
    return {
      err: [],
      editActive: false,
      oldPassword: '',
      errorOldPass: false,
      newPassword: '',
      repeatPassword: '',
      errorPass: false,
      user: {
        profile_additional_info: {},
      },
    };
  },
  computed: {
    email() {
      return this.$store.getters.user.email;
    },
    sex() {
      return this.$store.getters.sex;
    },
    settings() {
      return this.$store.getters.settings;
    },
  },
  watch: {
    /**
     * Валидация пароля
     *
     * @param v {string}
     */
    newPassword(v) {
      this.validator.validate('fPassword', v).catch(() => {});
    },

    /**
     * Валидация повторного пароля
     *
     * @param v {string}
     */
    repeatPassword(v) {
      this.validator.validate('lPassword', v).catch(() => {});
      this.validator.validate('fPassword', this.newPassword).catch(() => {});
    },
  },
  created() {
    /**
     * Инициализация плагина с валидатором
     * @type {Validator}
     */
    this.validator = new Validator();
    this.validator.attach('lPassword', 'min:8', {
      prettyName: this.$t('settings.repeatPswd'),
    });
    this.validator.attach('fPassword', 'min:8|confirmed:lPassword', {
      prettyName: this.$t('settings.password'),
    });

    this.$set(this, 'err', this.validator.errorBag);
  },
  methods: {
    /**
     * Провалидировать пароль и в случае успеха сохранить его
     */
    sendPassword() {
      this.validator
        .validateAll({
          fPassword: this.newPassword,
          lPassword: this.repeatPassword,
        })
        .then((success) => {
          if (success) {
            this.$http
              .post('v1/user-settings/change-password', {
                access_token: window.localStorage['access-token'],
                new_password: this.newPassword,
                new_password_repeat: this.repeatPassword,
              })
              .then(
                (r) => {
                  if (r.body.status) {
                    this.$store.commit('addAlert', {
                      type: 'success',
                      text: this.$t('alerts.settingsSuccess'),
                    });
                  } else {
                    this.$store.commit('addAlert', {
                      type: 'error',
                      text: this.$t('alerts.someErr'),
                    });
                  }
                },
                () => {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                  });
                }
              );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';

.general-settings {
  padding: 30px 25px;
  background: #f6f6f6;

  &__item {
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid $grey-two;
    position: relative;
    margin-bottom: 30px;

    &:before {
      content: '';
      position: absolute;
      bottom: -3px;
      display: block;
      left: 0;
      width: 5%;
      height: 3px;
      background: $red;
    }

    p {
      margin-bottom: 0;
      color: $black;
      font-size: 14px;
      letter-spacing: 1.4px;
    }

    &--name {
      flex: 0 0 25%;
      font-weight: 600;
    }
    &--value {
      font-weight: 300;
      flex: 0 0 75%;
    }
    &--edit {
      position: absolute;
      bottom: -20px;
      right: 0;
      background: $light-grey;
      padding: 10px;
      text-transform: uppercase;
      color: $black;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1.2px;
      cursor: pointer;
      &:hover {
        color: $red;
      }
    }

    &.line-off {
      border: none;
      margin: 0;
      .form-group {
        width: 50%;
        .form-control {
          width: 100%;
          background: none;
        }
      }
      &:before {
        display: none;
      }
    }
  }
}
</style>
