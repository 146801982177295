<template>
  <div>
    <div v-if="preloadPage" class="loader--wrap">
      <div class="loader" />
    </div>
    <div v-if="!preloadPage" class="general-settings">
      <div role="tabpanel" class="tab-pane">
        <h2 class="tab-heading hidden-xs">
          {{ $t('settings.notifications') }}
        </h2>
        <div class="settings-wrap-item">
          <form @submit.prevent="sendSettings">
            <div class="control-box">
              <div class="control-box__heading">
                <div class="item control-name">
                  <span>{{ $t('settings.sys') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item">
                    <span class="rbi rbi-duplicate" />
                  </div>
                  <div class="item">
                    <span class="rbi rbi-volume" />
                  </div>
                  <div class="item">
                    <span class="rbi rbi-arroba" />
                  </div>
                </div>
              </div>
              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.notifSys') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <custom-checkbox
                    id="system-notify-sound"
                    v-model="checkboxes.sound_system_notification"
                    class="item"
                  />
                  <custom-checkbox
                    id="system-notify-mail"
                    v-model="checkboxes.email_system_notification"
                    class="item"
                  />
                </div>
              </div>
            </div>
            <div class="control-box">
              <div class="control-box__heading">
                <div class="item control-name">
                  <span>{{ $t('settings.usrs') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item">
                    <span class="rbi rbi-duplicate" />
                  </div>
                  <div class="item">
                    <span class="rbi rbi-volume" />
                  </div>
                  <div class="item">
                    <span class="rbi rbi-arroba" />
                  </div>
                </div>
              </div>
              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.notifWink') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <custom-checkbox
                    v-if="sex.isMan"
                    id="wink-notify-window"
                    v-model="checkboxes.notification_winks"
                    class="item"
                  />
                  <div v-else class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <div v-if="sex.isWoman || !checkboxes.notification_winks" class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <custom-checkbox
                    v-if="!sex.isWoman && checkboxes.notification_winks"
                    id="wink-notify-sound"
                    v-model="checkboxes.sound_new_winks"
                    class="item"
                  />
                  <custom-checkbox
                    id="wink-notify-mail"
                    v-model="checkboxes.email_wink_notification"
                    class="item"
                  />
                </div>
              </div>
              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.notifFavs') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <custom-checkbox
                    v-if="sex.isMan"
                    id="favorite-notify-window"
                    v-model="checkboxes.notification_favorite"
                    class="item"
                  />
                  <div v-else class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <div v-if="sex.isWoman || !checkboxes.notification_favorite" class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <custom-checkbox
                    v-if="!sex.isWoman && checkboxes.notification_favorite"
                    id="favorite-notify-sound"
                    v-model="checkboxes.sound_new_favorite"
                    class="item"
                  />
                  <custom-checkbox
                    id="favorite-notify-mail"
                    v-model="checkboxes.email_favorite_notification"
                    class="item"
                  />
                </div>
              </div>
              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.notifGuest') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <custom-checkbox
                    v-if="sex.isMan"
                    id="guest-notify-window"
                    v-model="checkboxes.notification_guest"
                    class="item"
                  />
                  <div v-else class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <div v-if="sex.isWoman || !checkboxes.notification_guest" class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <custom-checkbox
                    v-if="!sex.isWoman && checkboxes.notification_guest"
                    id="guest-notify-sound"
                    v-model="checkboxes.sound_new_guest"
                    class="item"
                  />
                  <custom-checkbox
                    id="guest-notify-mail"
                    v-model="checkboxes.email_guest_notification"
                    class="item"
                  />
                </div>
              </div>
              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.notifLetter') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <custom-checkbox
                    v-if="sex.isMan"
                    id="letter-notify-window"
                    v-model="checkboxes.notification_inbox"
                    class="item"
                  />
                  <div v-else class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <div v-if="sex.isWoman || !checkboxes.notification_inbox" class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <custom-checkbox
                    v-if="!sex.isWoman && checkboxes.notification_inbox"
                    id="letter-notify-sound"
                    v-model="checkboxes.sound_new_inbox"
                    class="item"
                  />
                  <custom-checkbox
                    id="letter-notify-mail"
                    v-model="checkboxes.email_inbox_notification"
                    class="item"
                  />
                </div>
              </div>
            </div>
            <div class="control-box">
              <div class="control-box__heading">
                <div class="item control-name">
                  <span>{{ $t('settings.chat') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item">
                    <span class="rbi rbi-duplicate" />
                  </div>
                  <div class="item">
                    <span class="rbi rbi-volume" />
                  </div>
                  <div class="item">
                    <span class="rbi rbi-arroba" />
                  </div>
                </div>
              </div>
              <!--              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.chatSounds') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item"></div>
                  <custom-checkbox
                    class="item"
                    v-model="checkboxes.sound_chat"
                    id="chat-invite-sound"
                  ></custom-checkbox>

                  <div class="item"></div>
                </div>
              </div>-->
              <div class="control-box__body">
                <div class="item control-name">
                  <span>{{ $t('settings.notifChat') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item">
                    <span class="rbi rbi-lock" />
                  </div>
                  <custom-checkbox
                    id="chat-notify-sound"
                    v-model="checkboxes.sound_chat_notification"
                    class="item"
                  />
                  <div class="item custom-checkbox" />
                </div>
              </div>
              <!--              <div class="control-box__body" v-if="sex.isWoman">
                <div class="item control-name">
                  <span>{{ $t('settings.msgActChat') }}</span>
                </div>
                <div class="item control-checkboxes">
                  <div class="item"></div>
                  <custom-checkbox
                    class="item"
                    v-model="checkboxes.sound_chat_message_active"
                    id="chat-msg-active"
                  ></custom-checkbox>
                  <div class="item custom-checkbox"></div>
                </div>
              </div>-->
            </div>
            <div class="control-box btn-wrap checkboxes-submit">
              <rb-button type="submit" class="btn-black">
                {{ $t('settings.save') }}
              </rb-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customCheckbox from '../../Shared/parts/CustomCheckbox';
import RbButton from '../../Button';

export default {
  components: {
    customCheckbox,
    RbButton,
  },
  data() {
    return {
      preloadPage: true,
      checkboxes: {
        email_system_notification: false,
        email_favorite_notification: false,
        email_inbox_notification: false,
        email_guest_notification: false,
        email_wink_notification: false,
        sound: false,
        sound_new_winks: false,
        notification_inbox: false,
        notification_guest: false,
        sound_chat_notification: false,
        sound_new_guest: false,
        sound_new_inbox: false,
        notification_winks: false,
        sound_new_favorite: false,
        notification_favorite: false,
        sound_chat: false,
        sound_chat_message_active: false,
        sound_system_notification: false,
      },
    };
  },
  computed: {
    /**
     * Созданное вычисляемое свойство для отслеживания их в Watcher'е
     * @return {boolean}
     */
    notifInbox() {
      return this.checkboxes.notification_inbox;
    },
    /**
     * Созданное вычисляемое свойство для отслеживания их в Watcher'е
     * @return {boolean}
     */
    notifGuest() {
      return this.checkboxes.notification_guest;
    },
    /**
     * Созданное вычисляемое свойство для отслеживания их в Watcher'е
     * @return {boolean}
     */
    notifWink() {
      return this.checkboxes.notification_winks;
    },
    /**
     * Созданное вычисляемое свойство для отслеживания их в Watcher'е
     * @return {boolean}
     */
    notifFav() {
      return this.checkboxes.notification_favorite;
    },
    sex() {
      return this.$store.getters.sex;
    },
    settings() {
      return this.$store.getters.settings;
    },
  },
  watch: {
    /**
     * Спрятать чекбокс с звуки/письма в случае, если чекбокс с уведомления/письма был отключен
     * @param v {boolean}
     */
    notifInbox(v) {
      if (!v) this.checkboxes.sound_new_inbox = v;
    },

    /**
     * Спрятать чекбокс с звуки/гости в случае, если чекбокс с уведомления/гости был отключен
     * @param v {boolean}
     */
    notifGuest(v) {
      if (!v) this.checkboxes.sound_new_guest = v;
    },

    /**
     * Спрятать чекбокс с звуки/подмигивания в случае, если чекбокс с уведомления/подмигивания был отключен
     * @param v {boolean}
     */
    notifWink(v) {
      if (!v) this.checkboxes.sound_new_winks = v;
    },

    /**
     * Спрятать чекбокс с звуки/фавориты в случае, если чекбокс с уведомления/фавориты был отключен
     * @param v {boolean}
     */
    notifFav(v) {
      if (!v) this.checkboxes.sound_new_favorite = v;
    },
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    /**
     * Трансформируем String представление в Boolean после получения с сервера
     *
     * @param v {string}
     * @return {boolean}
     */
    strToBool(v) {
      if (v === 'on' || v === 'custom') {
        return true;
      }
      if (v === 'off') {
        return false;
      }
    },

    /**
     * Трансформировать данные после получение с сервера в соответствии с требованиями для input полей
     * @param tmp
     */
    initSettings(tmp) {
      for (const k in tmp) {
        tmp[k] = this.strToBool(tmp[k]);
      }
      this.checkboxes = Object.assign(this.checkboxes, tmp);
    },
    /**
     * Сконвертировать Boolean в String перед отправкой на сервер
     *
     * @param v {boolean}
     * @return {string}
     */
    makeString(v) {
      return v ? 'on' : 'off';
    },

    /**
     * Получить настройки с сервера
     */
    getSettings() {
      this.$http
        .post('v1/user-settings/get', {
          access_token: window.localStorage['access-token'],
        })
        .then(
          (response) => {
            if (response.body.status) {
              this.preloadPage = false;
              this.initSettings(response.body.result);
            } else {
              this.$store.dispatch('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'get settings',
              });
            }
          },
          () => {
            this.$store.dispatch('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'get settings',
            });
          }
        );
    },

    /**
     * Сохранить новые настройки на сервере
     */
    sendSettings() {
      this.$http
        .post('v1/user-settings/set', {
          access_token: window.localStorage['access-token'],
          email_system_notification: this.makeString(this.checkboxes.email_system_notification),
          email_favorite_notification: this.makeString(this.checkboxes.email_favorite_notification),
          email_inbox_notification: this.makeString(this.checkboxes.email_inbox_notification),
          email_guest_notification: this.makeString(this.checkboxes.email_guest_notification),
          email_wink_notification: this.makeString(this.checkboxes.email_wink_notification),
          sound: this.makeString(this.checkboxes.sound),
          sound_new_winks: this.makeString(this.checkboxes.sound_new_winks),
          sound_chat: this.makeString(this.checkboxes.sound_chat),
          sound_chat_notification: this.makeString(this.checkboxes.sound_chat_notification),
          sound_chat_message_active: this.makeString(this.checkboxes.sound_chat_message_active),
          sound_new_guest: this.makeString(this.checkboxes.sound_new_guest),
          sound_new_inbox: this.makeString(this.checkboxes.sound_new_inbox),
          sound_new_favorite: this.makeString(this.checkboxes.sound_new_favorite),
          sound_system_notification: this.makeString(this.checkboxes.sound_system_notification),
          notification_inbox: this.makeString(this.checkboxes.notification_inbox),
          notification_guest: this.makeString(this.checkboxes.notification_guest),
          notification_winks: this.makeString(this.checkboxes.notification_winks),
          notification_favorite: this.makeString(this.checkboxes.notification_favorite),
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$socket.emit('updateSettings', {
                token: window.localStorage.chat_token,
                type: 'site',
              });
              this.$store.dispatch('getUserSettings');
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.settingsSuccess'),
              });
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';

.general-settings {
  padding: 30px 25px;
  background: #f6f6f6;
}

.loader--wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.tab-heading {
  color: $black;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;

  &:first-letter {
    color: $red;
  }
}
</style>
