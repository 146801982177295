<template>
  <div class="main-block user-settings-wrap">
    <div class="settings-box white-box">
      <div class="d-lg-none">
        <div class="r-nav-title">
          <div class="item">
            <h3 class="header-title">
              {{ $t('settings.title') }}
            </h3>
          </div>
          <div class="item">
            <rb-button class="btn-back" style="color: black" @click.native="goBack">
              <span class="rbi rbi-left-arrow" />
              <span>{{ $t('settings.back') }}</span>
            </rb-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-none d-lg-block col-3">
          <div class="tab-box">
            <h3 class="header-title hidden-xs">
              {{ $t('settings.title') }}
            </h3>
            <ul role="tablist" class="tab-list">
              <router-link tag="li" :to="{ name: 'settings' }" active-class="active" exact>
                <a><span class="rbi rbi-gear" />{{ $t('settings.general') }}</a>
              </router-link>
              <router-link
                tag="li"
                :to="{ name: 'settings-notifications' }"
                active-class="active"
                exact
              >
                <a> <span class="rbi rbi-bell" />{{ $t('settings.notifications') }}</a>
              </router-link>
            </ul>
          </div>
        </div>
        <div class="col-md-12 col-lg-9">
          <div class="visible-xs tab-box d-lg-none">
            <ul role="tablist" class="tab-list-panel">
              <router-link tag="li" :to="{ name: 'settings' }" active-class="active" exact>
                <a>{{ $t('settings.general') }}</a>
              </router-link>
              <router-link
                tag="li"
                :to="{ name: 'settings-notifications' }"
                active-class="active"
                exact
              >
                <a>{{ $t('settings.notifications') }}</a>
              </router-link>
            </ul>
          </div>
          <div class="tab-content">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RbButton from '../Button';

export default {
  components: {
    RbButton,
  },
  mounted() {
    document.title = this.$t('settings.title');
  },
  methods: {
    /**
     * Перейти по истории браузера на 1 шаг назад
     */
    goBack() {
      try {
        this.$router.go(-1);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vars';

.user-settings-wrap {
  .settings-box {
    padding: 35px 25px;
  }
  .header-title {
    color: $black;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 1px;
    margin: 0;
    &:first-letter {
      color: $red;
    }
  }
}
</style>
